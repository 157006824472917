// import Vue from "vue";
import axios from "axios";
// import vue from "vue";
// import httpClient from "core/common";
// import { HttpHeaders } from "./http-common";
// window.axios = require("axios");
// const API_URL = "https//mmuoka.com/elbox_back/public/api/";

const BASE_URL = "https://myshelta.com/shelterbackend/public/api";
//const BASE_URL = "https://myshelta.com/Testbackend/public/api";




export class Service {
  token;
  $router;
  // const = getDataDetails;
  constructor() {
    this.token = this.getToken();
  }


  getRequest(url) {
    return axios.get(`${BASE_URL}/${url}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.token
      }
    });
  }

  userProfile() {
    if (localStorage.getItem("shelta-user")) {
      const id = JSON.parse(localStorage.getItem("shelta-user") || "{}").id;
      this.getRequest(`getusercompleteprofile/${id}`)
        .then(res => {
          // console.log("response for user profile", res);
          if (res.status == 401) {
            window.localStorage.clear();
            window.location.href = "http://localhost:8080/login";
          }
        })
        .catch(err => {
          console.log("err:", err);
        });
    }
  }


  getRequestNoAuth2(url) {
    return axios.get(`/${url}`);
  }

  getRequestNoAuth(url) {
    return axios.get(`${BASE_URL}/${url}`);
  }

  postRequest(url, data) {
    return axios.post(`${BASE_URL}/${url}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.token
      }
    });
  }

  postRequestNoAuth(url, data) {
    return axios.post(`${BASE_URL}/${url}`, data);
  }

  setToken(token) {
    localStorage.setItem("shelta", token);
  }

  getToken() {
    return localStorage.getItem("shelta");
  }



 

  
}
